import React, { useState, useEffect, useRef } from "react";
import { format_pub_key_shorter } from "../utils/funcs";
import useNFTStaking from "../hooks/useNFTStaking";
import { NFTStake } from "../states";
import "./css/stakeRow.css";
import { Button, Spin } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import useTokenVault from "../hooks/useTokenVault";
import useWithdrawal from "../hooks/useWIthdrawal";
import { copy } from "../utils/funcs";
import { timeSince } from "../utils/funcs";
import { DC_TOKEN_DECIMAL, STAKE_DATA_API } from "../utils/ids";
import { success, error } from "../utils/Mesg";
import useToken from "../hooks/useToken";
import { TokenImageView } from "./TokenImageView";
import "./css/Stakerows.css";
import Icon from "./Icon";

export interface StakeRowProps {
  pubkey: string;

  index: number;

  setTokenEarnedAt: (index: number, value: number) => void;
}

export const StakeRow: React.FC<StakeRowProps> = ({
  pubkey,
  index,
  setTokenEarnedAt,
}) => {
  const [, , , readNFTStake, calTokenEarned, , , ,] = useNFTStaking();

  const [unstake] = useWithdrawal();

  const [, , , readTokenVault] = useTokenVault();

  const [, , , , , , loadMetadata] = useToken();

  const [forMonth, setForMonth] = useState(0);

  const [rate, setRate] = useState(0);

  const [dateStaked, setDateStaked] = useState<Date>();

  const [lastUpdated, setLastUpdated] = useState<Date>();

  const [created, setCreated] = useState<Date>();

  const [toLoad, setToLoad] = useState(false);

  const [tokenEarned, setTokenEarned] = useState(0);

  const [nftMint, setNftMint] = useState("");

  const [pdaKey, setPdaKey] = useState("");

  const [tokenRewarded, setTokenRewarded] = useState(0);

  //const [nftTokenAccKey, setNftTokenAccKey] = useState("");

  const [stat, setStat] = useState(0);

  const [imageUrl, setImageUrl] = useState<string>();

  const [working, setWorking] = useState(false);

  const timePauseToFetchAfterAction = 2000;

  // async function readAllNfts(completionHandler: (result : Array<NFTStakeAddr> )=> void) {

  //     let indexKey = await indexPubKey();

  // 	await readIndex( indexKey,

  // 		async (res: Index | Error) => {
  // 			if (!(res instanceof Error)) {

  //                 let allNFts = await readAllNFTs(res.nfts);
  //                 completionHandler(allNFts);
  //                 return;
  // 			}
  // 		}
  // 	);

  //     completionHandler([]);

  // }

  async function readNFTNoAddingToParent() {
    await readNFT(false);
  }

  async function readNFT(toAddNFT: boolean = true) {
    readNFTStake(
      pubkey,

      (res: NFTStake | Error) => {
        if (!(res instanceof Error)) {
          setForMonth(res.for_month);

          let d = new Date(res.stake_date * 1000);

          setDateStaked(d);

          setRate(res.rate);

          let lupd = new Date(res.last_update * 1000);

          setLastUpdated(lupd);

          setTokenRewarded(res.token_reward);

          let tkRw = (tokenRewarded / Math.pow(10, DC_TOKEN_DECIMAL)) * 100000;

          let t = res.stat === 0 ? calTokenEarned(d, res.rate, tkRw) : tkRw;

          //calTokenEarned( d , res.rate , lupd);

          setTokenEarned(t);

          setNftMint(res.nft_mint.toBase58());

          setPdaKey(res.pda.toBase58());

          setStat(res.stat);

          let _created = new Date(res.created * 1000);

          setCreated(_created);

          if (toAddNFT) {
            loadMetadata(res.meta_key, (res: string | Error) => {
              if (!(res instanceof Error)) {
                setImageUrl(res);
              }
            });
          }
        }

        setWorking(false);
      }
    );
  }

  useEffect(() => {
    readNFT();

    return;
  }, []);

  useEffect(() => {
    let tkRw = (tokenRewarded / Math.pow(10, DC_TOKEN_DECIMAL)) * 100000;

    let t = stat === 0 ? calTokenEarned(dateStaked, rate, tkRw) : tkRw;
    //calTokenEarned(dateStaked, rate, lastUpdated);

    let nextReloadTime;

    if (tokenEarned == 0) {
      nextReloadTime = 500;
    } else {
      nextReloadTime = 3000;
    }

    setTokenEarned(t);

    setTokenEarnedAt(index, t);

    setTimeout(() => {
      setToLoad(!toLoad);
    }, nextReloadTime);

    return;
  }, [toLoad]);

  async function buttonAction() {
    readTokenVault(nftMint, (acc: string | Error) => {
      if (!(acc instanceof Error)) {
        setWorking(true);

        if (stat != 1) {
          setWorking(true);
          unstake(nftMint, pubkey, pdaKey, acc, (res: boolean | Error) => {
            if (res instanceof Error) {
              setWorking(false);
              error(res.message);
              setWorking(false);
            } else {
              if (res) {
                setWorking(false);

                success("Success", 3);

                if (forMonth != 0) {
                  fetch(STAKE_DATA_API, {
                    method: "POST",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      stakedMain: 0,
                      burned: 0,
                      stakedSup: -1,
                    }),
                  });
                } else {
                  fetch(STAKE_DATA_API, {
                    method: "POST",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      stakedMain: -1,
                      burned: 0,
                      stakedSup: 0,
                    }),
                  });
                }

                setTimeout(
                  readNFTNoAddingToParent,
                  timePauseToFetchAfterAction
                );
              }
            }
          });
        }
      }
    });
  }
  const [size, setSize] = useState(window.innerWidth);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setSize(window.innerWidth);
  }, [window.innerWidth]);

  return (
    <>
      {size > 600 ? (
        <tr
          className="stakeRow"
          style={{ verticalAlign: "top", display: stat == 1 ? "none" : "" }}
        >
          <td
            style={{ width: "7%", margin: "10px", verticalAlign: "top" }}
            title={nftMint}
          >
            {imageUrl ? (
              <TokenImageView uri={imageUrl} />
            ) : (
              <Spin style={{ marginRight: "5px" }} size="small" />
            )}
          </td>
          <td
            style={{
              width: "20%",
              textAlign: "left",
              margin: "10px",
              verticalAlign: "top",
            }}
            title={nftMint}
          >
            {format_pub_key_shorter(nftMint)}
            <Button
              shape="circle"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                copy(nftMint);
              }}
            >
              <CopyOutlined />
            </Button>
          </td>
          <td style={{ width: "10%", margin: "10px" }}>
            {forMonth === 0 ? "DC Main" : "Supplementary"}
          </td>
          <td
            title={
              (dateStaked?.toLocaleString() ?? "none") +
                " first staked on " +
                created?.toLocaleString() ?? ""
            }
            style={{
              textAlign: "justify",
              width: "10%",
              margin: "10px",
              paddingLeft: "4vw",
            }}
          >
            {dateStaked ? timeSince(dateStaked) : ""}
          </td>
          <td
            style={{
              textAlign: "justify",
              width: "10%",
              margin: "10px",
              paddingLeft: "2vw",
            }}
          >
            {tokenEarned.toFixed(5)}
          </td>
          <td style={{ width: "10%", margin: "10px" }}>{rate} $DAWG / day</td>
          <td style={{ width: "10%", margin: "10px" }}>
            <Button
              className="stakeButton"
              onClick={async () => {
                await buttonAction();
              }}
   
            >
              {working ? (
                <Spin size="small" />
              ) : (
                <div className="relative flex flex-col items-center">
                  <Icon icon="unstake-btn" width={100} zoom={1.3}/>
                  <p className="top-1 absolute" style={{ top: "0.3rem" }}>
                    {stat === 1 ? "Stake" : "Unstake"}
                  </p>
                </div>
              )}
            </Button>
          </td>
        </tr>
      ) : (
        <div
          style={{
            fontSize: "0.7rem",
          }}
          className="stakelist-rows m-2 py-2"
        >
          <div
            className=" grid grid-cols-7 m-2 gap-2"
            onClick={() => setToggle(!toggle)}
          >
            <div className="col-span-1 flex items-center" title={nftMint}>
              {imageUrl ? (
                <TokenImageView uri={imageUrl} />
              ) : (
                <Spin style={{ marginRight: "5px" }} size="small" />
              )}
            </div>

            <div className="col-span-2 sm:text-center flex items-center">
              {rate} $DAWG / day
            </div>

            <div className="col-span-2 flex items-center">{tokenEarned.toFixed(5)}</div>
            <div className="col-span-2">
              <Button
                className="stakeButton"
                onClick={async () => {
                  await buttonAction();
                }}
              >
                {working ? (
                  <Spin size="small" />
                ) : (
                  <div className="relative flex flex-col items-center">
                    <Icon icon="unstake-btn" width={100} />
                    <p className="absolute flex top-1/4 text-white">
                      {stat === 1 ? "Stake" : "Unstake"}
                    </p>
                  </div>
                )}
              </Button>
            </div>
          </div>
          <div className={toggle ? "open" : "close"}>
            <div
              style={{
                fontSize: "0.7rem",
              }}
              className="grid grid-cols-3 gap-1 m-4 mt-4 "
            >
              <div className="col-span">Staked nft</div>
              <div className="">Type</div>
              <div className="">Date staked</div>
            </div>
            <hr className="mx-4" />
            <div
              style={{
                fontSize: "0.6rem",
              }}
              className="grid grid-cols-3 gap-1 m-4"
            >
              <div className="" title={nftMint}>
                {format_pub_key_shorter(nftMint)}
                <Button
                  shape="circle"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    copy(nftMint);
                  }}
                >
                  <CopyOutlined />
                </Button>
              </div>
              <div className="">
                {forMonth === 0 ? "DC Main" : "Supplementary"}
              </div>
              <div
                className=""
                title={
                  (dateStaked?.toLocaleString() ?? "none") +
                    " first staked on " +
                    created?.toLocaleString() ?? ""
                }
              >
                {dateStaked ? timeSince(dateStaked) : ""}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
