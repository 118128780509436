import { FC, useState } from "react";
import { ViewType } from "./forms/TokenForm";
import { TokenTxForm } from "./forms/TokenTxForm";
import { SysForm } from "./forms/SysForm";
import { Button } from "antd";
import "./css/dcTokenVaultPage.css";

export const DCTokenPageView: FC = () => {
	const [viewType, setViewType] = useState(ViewType.SysForm);

	const optionView = (
		<div style={{ marginTop: "30px" }}>
			<p>
				<Button
					title="Create DC Vault"
					className={
						viewType === ViewType.SysForm ? "menuButtonSel" : "menuButton"
					}
					onClick={() => {
						setViewType(ViewType.SysForm);
					}}
				>
					Create DC Vault
				</Button>

				<Button
					title="Tx $DC Token To System"
					className={
						viewType === ViewType.DCTokenTxForm ? "menuButtonSel" : "menuButton"
					}
					onClick={() => {
						setViewType(ViewType.DCTokenTxForm);
					}}
				>
					TX $DC Token
				</Button>
			</p>
		</div>
	);

	const switchView = (viewType: ViewType): any => {
		switch (viewType) {
			case ViewType.DCTokenTxForm:
				return <TokenTxForm setViewType={setViewType} />;

			case ViewType.SysForm:
				return <SysForm setViewType={setViewType} />;
		}
	};

	return (
		<div className="dcTokenVaultForm">
			<p>{optionView}</p>
			<p>{switchView(viewType)}</p>
		</div>
	);
};
