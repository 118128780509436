import {FC,useState} from 'react';
import { Form, Input, Button, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import '../css/common.css';
import { ViewTypeProps, ViewType } from './TokenForm';
import useDcSys from '../../hooks/useDcSys';
import { Sys } from '../../states';
import * as web3 from '@solana/web3.js';
import useSolana from '../../hooks/useSolana';
import { CopyOutlined } from '@ant-design/icons';
import { copy , formatNumber} from '../../utils/funcs';

export const TokenTxForm : FC <ViewTypeProps> = ({ setViewType }) => {

    const [,,readSys] = useDcSys();

    const { Option } = Select;

    const [tokenCount, setTokenCount] = useState(0);

    const [decimal, setDecimal] = useState(0);

    const [balance, setBalance] = useState(0);

    const [connection] = useSolana();

    const completion = (res : boolean | Error) =>  {

        if (res instanceof Error){
     
            alert(res.message);
        }
        else {

            alert("Success!");
        }
    }

    const[,txToken] = useDcSys();

    const [sys, setSys] = useState<Sys>();

    const tokenCountOnChange = (e: React.FormEvent<HTMLInputElement>): void => {

        let txt = e.currentTarget.value;
        let v = parseInt(txt);
      
        setTokenCount(v); 
      
    };


    const decimalOnChange = (value : SelectValue): void => {

        let txt = value?.toString() ?? "0";
        let v = parseInt(txt);
      
        setDecimal(v);
    };


    const txForm =  <div><p style={{fontWeight:"bolder",fontSize:"12pt"}}>Transfer $DC Token To System PDA</p>
    <Form layout="vertical" style={{color:"black",textAlign:"left"}}>
         <Form.Item label={<label style={{ color: "black" }}>Token Count</label>} 
             required>
             <Input placeholder="Token Count"  style={{maxWidth:"600px"}} onChange={tokenCountOnChange}/>
         </Form.Item>
 
         <Form.Item label={<label style={{ color: "black" }}>Decimal (NFT is 0 max is 9)</label>} 
             required>
            
             <Select style={{maxWidth:"200px"}} defaultValue="6" placeholder="Decimal" onChange={decimalOnChange}>
 
             <Option value="0">NFT (0)</Option>
             <Option value="1">1</Option>
             <Option value="2">2</Option>
             <Option value="3">3</Option>
             <Option value="4">4</Option>
             <Option value="5">5</Option>
             <Option value="6">6</Option>
             <Option value="7">7</Option>
             <Option value="8">8</Option>
             <Option value="9">9</Option>
 
             </Select>
         </Form.Item>
 
     </Form>
     <p> <Button className="submitButton"
    onClick={async ()=> {
      
        await txToken(tokenCount, decimal, completion);

    }} >Submit</Button>

    <Button className="cancelButton"
    onClick={()=> {
      
        setViewType( ViewType.Menu );

    }} >Cancel</Button></p>
     
     </div>
 

  

    async function readSysNow(){

       
        await readSys((res : Error | Sys) => {

            if (!(res instanceof Error)){
 
                if ( res.token_account.toBase58() != web3.PublicKey.default.toBase58()){
        
                    setSys(res);

                    
                    let bal = connection.getTokenAccountBalance(res.token_account);

                    bal.then((v)=>{

                        setBalance(v.value.uiAmount ?? 0);
                  
                    })
                }
            }
            else {

        
                console.log("res.err", res.message);
            }

        })
    }

   
    

    return <div style={{maxWidth:"600px",margin:"auto"}}><br/>
   
    <p>
    <Button className="actionButton"
    onClick={()=> {
      
        readSysNow();

    }} >Fetch Balance</Button></p>

    <div style={{textAlign:"left",maxWidth:"500px", color:"white", margin:"auto",fontSize:"12pt"}}>
    <p style={{background:"#123",padding:"10px"}}>$DC Token Mint : {sys?.token_mint?.toBase58()}  <Button shape="circle" style={{marginLeft:"10px", display : sys?.token_mint? "inline-block" : "none" }} onClick={()=>{
            copy(sys?.token_mint.toBase58() ?? "");
        }}><CopyOutlined /></Button>
       </p>

    <p style={{background:"#333",padding:"10px"}}>Info : For DC Admin, please transfer the $DC Token To the vault account below :</p>
    <p style={{background:"#372",padding:"10px", border:"1px solid #900"}}>$DC Token Vault Account : {sys?.token_account?.toBase58()} <Button shape="circle" 
         style={{marginLeft:"10px", display : sys?.token_account? "inline-block" : "none" }} onClick={()=>{
            copy(sys?.token_mint.toBase58() ?? "");
        }}><CopyOutlined /></Button></p>
    <p style={{background:"#236",padding:"10px"}}>Amount In Vault : {formatNumber(balance)}</p>
    </div>
    </div>
}