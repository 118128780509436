import * as web3 from "@solana/web3.js";
import useSolana from "./useSolana";
import useToken from "./useToken";
import { programId } from "../utils/ids";
import * as splToken from "@solana/spl-token";
import { NFTStakeAddr } from "../states";
import { parse_sys, Sys,} from "../states";
import useNFTStaking from "./useNFTStaking";
import { num_to_u32 } from "../states";
import { DC_TOKEN_VAULT_OWNER, DC_TOKEN_DECIMAL } from "../utils/ids";
import useDcSys from "./useDcSys";
import { TOKEN_PROGRAM_ID , ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';

export default function useWithdrawal() {
	const [connection, publicKey, , , , setLoading, sendTxs] = useSolana();

	const [, , , sysAccountKey] = useDcSys();

	const [findAssociatedTokenAddress, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID] = useToken();

	const [, , indexPubkey] = useNFTStaking();

	function createUnstakeBytes(): Buffer {
		const newInsArray: Uint8Array = new Uint8Array(2);
		newInsArray[0] = 2; // the mod 2 is the staking mod
		newInsArray[1] = 4; // the second element is the action in staking

		const dataBuffer = Buffer.from(newInsArray);

		return dataBuffer;
	}

	async function unstake(
		nftMint: string,
		stakeKey: string,
		pdaAcckey: string,
		vaultTokenAccKey: string,
		completionHandler: (result: boolean | Error) => void
	) {
		if (!publicKey) {
			return;
		}

		let stakeAccount = new web3.PublicKey(stakeKey);
		let signerTokenAcc = await findAssociatedTokenAddress(
			publicKey,
			new web3.PublicKey(nftMint)
		);


		const allTxs = new web3.Transaction();

		allTxs.feePayer = publicKey;

		allTxs.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

		if(await connection.getAccountInfo(signerTokenAcc)==null){
			
		allTxs.add(

			splToken.Token.createAssociatedTokenAccountInstruction(
				ASSOCIATED_TOKEN_PROGRAM_ID,
				TOKEN_PROGRAM_ID,
				new web3.PublicKey(nftMint),
				signerTokenAcc,
				publicKey,
				publicKey
			)

		  )
	   }

		let vaultTokenAccount = new web3.PublicKey(vaultTokenAccKey);
		let pdaAcc = new web3.PublicKey(pdaAcckey);

		//let nftTokenAcc =  new web3.PublicKey(nftTokenAccKey) ;

		/*
       let token_program = next_account_info(account_info_iter)?;
       let stake_account = next_account_info(account_info_iter)?;
       let nft_token_account = next_account_info(account_info_iter)?;
       let vault_token_account = next_account_info(account_info_iter)?;
       */

		let accounts: Array<web3.AccountMeta> = [
			{ pubkey: splToken.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
			{ pubkey: signerTokenAcc, isSigner: false, isWritable: true },
			{ pubkey: vaultTokenAccount, isSigner: false, isWritable: true },
			{ pubkey: pdaAcc, isSigner: false, isWritable: true },
			{ pubkey: publicKey, isSigner: true, isWritable: false },
			{ pubkey: stakeAccount, isSigner: false, isWritable: true },
			{ pubkey: new web3.PublicKey(await indexPubkey()), isSigner: false, isWritable: true },
		];

		let data = createUnstakeBytes();

		const createStakeTxIns = new web3.TransactionInstruction({
			programId,
			keys: accounts,
			data: data,
		});

		allTxs.add(new web3.Transaction().add(createStakeTxIns));

		allTxs.feePayer = publicKey;

		sendTxs(allTxs, (res: string | Error) => {
			if (res instanceof Error) {
				completionHandler(res);
				setLoading(false);
			} else {
				console.log("tx.id::" + res);
				completionHandler(true);
				setLoading(false);
			}
		});
	}

	function createWithdrawalBytes(
		token_decimal: number,
		count: number,
		random_number: number
	): Buffer {
		const newInsArray: Uint8Array = new Uint8Array(8);
		newInsArray[0] = 2; // the mod 2 is the staking mod
		newInsArray[1] = 3; // the second element is the action in staking, 3 is for withdrawal

		var offset = 2;

		let abytes = num_to_u32(token_decimal);

		for (var r = 0; r < abytes.length; r++) {
			newInsArray[offset + r] = abytes[r];
		}

		offset += abytes.length;

		newInsArray[offset] = count;

		offset += 1;

		newInsArray[offset] = random_number;

		const dataBuffer = Buffer.from(newInsArray);

		return dataBuffer;
	}


	function checkQualifiedForWithdrawal(nfts: Array<NFTStakeAddr>){

		var hasMainNFT = false ;
		var hasSuppNFT = false ;

		
		nfts.map((nft,_idx)=>{

			if (!hasMainNFT) hasMainNFT = (nft.nft?.for_month === 0) ; 

			if ( !hasSuppNFT) hasSuppNFT = ((nft.nft?.for_month ?? 0) > 0 ) ;

		});

	
		return hasSuppNFT && hasMainNFT;
	}



	function stakedSuppNfts(	nfts: Array<NFTStakeAddr>) : Array<NFTStakeAddr> {

		let sNfts : Array<NFTStakeAddr> = [];

		nfts.map( (value, _index) => {
	
			if (value.nft?.stat === 0 && value.nft?.for_month > 0 ){

				sNfts.push(value);
			}
		});

		return sNfts; 
   }
	

   function stakedNfts(	nfts: Array<NFTStakeAddr>) : Array<NFTStakeAddr> {

		let sNfts : Array<NFTStakeAddr> = [];

		nfts.map( (value, _index) => {
	
			if (value.nft?.stat === 0){

				sNfts.push(value);
			}
		});

		return sNfts; 
   }

	async function withdraw(nfts: Array<NFTStakeAddr>,
		randomBurnInfoCallback: ((result: number) => void) | undefined = undefined,
		completionHandler: (result: string | Error, requireUnstake : boolean) => void) {
		if (!publicKey) {
			completionHandler(Error("! No wallet connected"), false);
			return;
		}

		let sysAccKey = await sysAccountKey(
			new web3.PublicKey(DC_TOKEN_VAULT_OWNER)
		);

		if (!sysAccKey) {
			completionHandler(Error("! Invalid $DC vault account key"), false);
			return;
		}
		//await web3.PublicKey.createWithSeed(publicKey, SYS_SEED, programId);

		let sysAcc = await connection.getAccountInfo(sysAccKey);

		if (sysAcc === null) {
			completionHandler(Error("No $DC token has been created!"), false);
			return;
		}

		// if ( !checkQualifiedForWithdrawal (nfts)){

		// 	completionHandler(
		// 		Error("! Withdrawal only allowed if you have at least one main NFT and one supplimentary NFT staked"), false);
		// 	return;
	
		// }
		

		

		setLoading(true);

		parse_sys(sysAcc.data, (res: Sys | Error) => {
			if (res instanceof Error) {
				completionHandler(res, false);

				setLoading(false);
			} else {
				try {
					withdrawing(
						publicKey,
						res,
						nfts,
						randomBurnInfoCallback,
						completionHandler
					);
				} catch (err) {
					console.log("err.x::", err);
					completionHandler(Error("error!"), false);
				}
			}
		});
	}

	
	async function withdrawing(
		signerPubkey: web3.PublicKey,
		sys: Sys,
		nfts: Array<NFTStakeAddr>,
		randomBurnInfoCallback: ((result: number) => void) | undefined = undefined,
		completionHandler: (result: string | Error, requireUnstake : boolean) => void
	) {
		let signerDcTokenAcc = await findAssociatedTokenAddress(
			signerPubkey,
			new web3.PublicKey(sys.token_mint)
		);
		

		const allTxs = new web3.Transaction();

		if ( await connection.getAccountInfo(signerDcTokenAcc) === null ){

			// if not, add and instruction of creating the associated token account to
			// web3 transaction to create it 
			  allTxs.add(
  
				  splToken.Token.createAssociatedTokenAccountInstruction(
					  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, splToken.TOKEN_PROGRAM_ID,
					  sys.token_mint, signerDcTokenAcc, signerPubkey, signerPubkey),
	  
			  );
	   }

		let indexAcc = new web3.PublicKey(await indexPubkey());

		let accounts: Array<web3.AccountMeta> = [
			{ pubkey: signerPubkey, isSigner: true, isWritable: false },
			{ pubkey: new web3.PublicKey(sys.token_mint),isSigner: false,isWritable: false,},
			{ pubkey: new web3.PublicKey(sys.token_account), isSigner: false,isWritable: true,},
			{ pubkey: new web3.PublicKey(sys.token_pda), isSigner: false, isWritable: true,},
			{ pubkey: signerDcTokenAcc, isSigner: false, isWritable: true },
			{ pubkey: splToken.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
			{ pubkey: indexAcc, isSigner: false, isWritable: true },			
		];

		// if(nfts[0]===undefined){
		// 	completionHandler(
		// 		Error("$DAWG can be withdrawn only if you have atlest 1 Woofer NFT staked !"), false);
		// 		return;
		// }

		// let suppNfts = stakedSuppNfts(nfts);

		// if(suppNfts[0]===undefined){
		// 	completionHandler(
		// 		Error("$DAWG can be withdrawn only if you have atlest 1 Woofer NFT staked !"), false);
		// 		return;
		// }

			
			// if (suppNfts[0].address.toBase58 () !== web3.PublicKey.default.toBase58()){

				accounts.push({pubkey: web3.PublicKey.default, isSigner: false, isWritable: true,});
	
				accounts.push({pubkey: web3.PublicKey.default,isSigner: false,isWritable: true,});

				accounts.push({pubkey: web3.PublicKey.default,isSigner: false,isWritable: true,});
	
				accounts.push({pubkey: web3.PublicKey.default,isSigner: false,isWritable: true,});
				
				//accounts.push({pubkey: suppNfts[0].address, isSigner: false,isWritable: true,});
		
			// }

		nfts.map((value)=>{

			if (value.address.toBase58 () !== web3.PublicKey.default.toBase58())
			accounts.push({pubkey: value.address?? web3.PublicKey.default, isSigner: false, isWritable: true,});

		})	
	

		let random_to_burn = 0;


		if (randomBurnInfoCallback) {
			randomBurnInfoCallback(random_to_burn);
		}

		let data = createWithdrawalBytes(
			DC_TOKEN_DECIMAL,
			nfts.length,
			random_to_burn
		);

	
		const withdrawalIns = new web3.TransactionInstruction({
			programId,
			keys: accounts,
			data: data,
		});

		allTxs.add(new web3.Transaction().add(withdrawalIns));

		allTxs.feePayer = signerPubkey;

		sendTxs(allTxs, (res: string | Error) => {
			if (res instanceof Error) {
				completionHandler(res, false);
				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	}

	
	return [unstake, withdraw, stakedSuppNfts] as const;
}
