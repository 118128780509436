import * as web3 from '@solana/web3.js';

export const FIRST_CREATOR_IS_LAST_INDEX = false ;//false; // change to false for mainnet as it's the first index 
                                                // instead of last


export const NETWORK = "mainnet-beta";

// mainnet program id : 6KpYLKfFECrobqkLJ53XW9VYcjzpbsNNuqFxbx4JGUox
export const programId : web3.PublicKey = 

//new web3.PublicKey("6ECSsEEcSQiNFxU5JL9Cgi6CoRxsD1bj9vxEbgKeHsm6");
// 6KpYLKfFECrobqkLJ53XW9VYcjzpbsNNuqFxbx4JGUox

new web3.PublicKey("6KpYLKfFECrobqkLJ53XW9VYcjzpbsNNuqFxbx4JGUox");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 9; 

export const DC_TOKEN_MINT = "3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "5uRrLck3P7JnJJhboNsCVFgCHuG6KY7CHN2k9EHhBdNv";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "https://thedogecapital.com/api/tokenvault?mint=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "6WewovgjK9ktSYqJ7YahwbufLphiTwogpzP6rwrr7JGx";

export const STAKE_DATA_API = "https://thedogecapital.com/api/stakeData";


export const SIGN_IN_API = "https://thedogecapital.com/api/signin";


export const SIGN_OUT_API = "https://thedogecapital.com/api/signout";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "5tT3dfDyZsbu4fGXMYZMF9upmwt7ji1JR4FYtnL2soLb";

export const DC_VALID_FIRST_CREATOR = "9n1VEM8Tat2hD5pmJiRb7Lx4z41xcG9hnj2Daop6L6qL";

/**
 * Devnet testing parameters
*/
/*
export const NETWORK = "devnet";

export const programId : web3.PublicKey = 

//new web3.PublicKey("6ECSsEEcSQiNFxU5JL9Cgi6CoRxsD1bj9vxEbgKeHsm6");
// 6KpYLKfFECrobqkLJ53XW9VYcjzpbsNNuqFxbx4JGUox

new web3.PublicKey("5E4MATRbVPrHsHKWyYUu1HvLS5dhhajuETdSCeuqDf64");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 6; 

export const DC_TOKEN_MINT = "G3cFV1vs3HwTrki3RZ48hQTa5AegUK9AWJqZ9Ye6cEEH";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "C9wYbsk6q1zxcwmXT8BfKgudipdbkp8NSsCqLzNqubns";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "http://localhost:3008/token?token=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "HYDB3uXShfH9fm8h5MaMzKVodE39AcdhkFpphXrBh9eF";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "H7a7ukVSEdGr56mkkXDjQhH1gZjcvMqEeVfSxk2dLdUK";

export const DC_VALID_FIRST_CREATOR = "9JS5p5o6k61JEQU4txqdLWpDzE98BL8Cm9vY4ovFLgVa";

*/


 