import { FC, useState } from "react";
import { Button } from "antd";
import "../css/common.css";
import { ViewTypeProps, ViewType } from "./TokenForm";
import { DC_TOKEN_MINT } from "../../utils/ids";
import useDcSys from "../../hooks/useDcSys";
import { Form, Input } from "antd";
import { Sys } from "../../states";
import * as web3 from "@solana/web3.js";
import { ReloadOutlined, CopyOutlined } from "@ant-design/icons";
import { copy } from "../../utils/funcs";

export const SysForm: FC<ViewTypeProps> = ({ setViewType }) => {
	const [createSys, , readSys] = useDcSys();

	const [tokenAccount, setTokenAccount] = useState("");

	const [dcTokenMint, setDcTokenMint] = useState("");

	const [sys, setSys] = useState<Sys>();

	const tokenMintOnChange = (e: React.FormEvent<HTMLInputElement>): void => {
		let txt = e.currentTarget.value;
		setDcTokenMint(txt);
	};

	const tokenAccCallback = (res: string) => {
		setTokenAccount(res);
	};

	const completion = (res: boolean | Error) => {
		if (res instanceof Error) {
			alert(res.message);

			readSysNow();
		} else {
			alert("Success!");
			readSysNow();
		}
	};

	const createSysForm = (
		<>
			<p>
				<Form.Item
					label={
						<label style={{ color: "white", fontSize: "14pt" }}>
							$DC Token Mint
						</label>
					}
					required
				>
					<Input
						placeholder={DC_TOKEN_MINT}
						style={{ maxWidth: "600px" }}
						onChange={tokenMintOnChange}
					/>
				</Form.Item>
			</p>
			<p>
				Token Account To Be Created{" "}
				<div
					style={{
						border: "1px solid #aaa",
						background: "#dfe",
						display: tokenAccount.trim() !== "" ? "block" : "none",
						padding: "4px",
						fontSize: "12pt",
					}}
				>
					{tokenAccount}
				</div>
			</p>
			<Button
				className="submitButton"
				onClick={async () => {
					await createSys(dcTokenMint, tokenAccCallback, completion);
				}}
			>
				Submit
			</Button>

			<Button
				className="cancelButton"
				onClick={() => {
					setViewType(ViewType.Menu);
				}}
			>
				Cancel
			</Button>
		</>
	);

	async function readSysNow() {
		await readSys((res: Error | Sys) => {
			if (!(res instanceof Error)) {
				if (
					res.token_account.toBase58() !== web3.PublicKey.default.toBase58()
				) {
					setSys(res);
				}
			} else {
				console.log("res.err", res.message);
			}
		});
	}

	const sysCreatedView = (
		<div
			style={{
				textAlign: "left",
				margin: "auto",
				marginTop: "50px",
				maxWidth: "500px",
				fontSize: "12pt",
				color: "white",
			}}
		>
			<p>
				$DC Token Vault Info{" "}
				<Button
					style={{ marginLeft: "20px" }}
					shape="circle"
					onClick={async () => {
						await readSysNow();
					}}
				>
					<ReloadOutlined />
				</Button>{" "}
			</p>
			<p>
				$DC Token Mint :<br />
				{sys?.token_mint.toBase58() ?? ""}
				<Button
					shape="circle"
					style={{
						marginLeft: "10px",
						display: sys?.token_mint ? "inline-block" : "none",
					}}
					onClick={() => {
						copy(sys?.token_mint.toBase58() ?? "");
					}}
				>
					<CopyOutlined />
				</Button>
			</p>
			<p>
				$DC Token Account:
				<br />
				{sys?.token_account.toBase58() ?? ""}
				<Button
					shape="circle"
					style={{
						marginLeft: "10px",
						display: sys?.token_account ? "inline-block" : "none",
					}}
					onClick={() => {
						copy(sys?.token_account.toBase58() ?? "");
					}}
				>
					<CopyOutlined />
				</Button>
			</p>
			<p>
				$DC Token PDA :<br />
				{sys?.token_pda.toBase58() ?? ""}
				<Button
					shape="circle"
					style={{
						marginLeft: "10px",
						display: sys?.token_pda ? "inline-block" : "none",
					}}
					onClick={() => {
						copy(sys?.token_pda.toBase58() ?? "");
					}}
				>
					<CopyOutlined />
				</Button>
			</p>
		</div>
	);

	return (
		<div style={{ margin: "auto", maxWidth: "600px" }}>
			{createSysForm}
			<br />
			{sysCreatedView}
		</div>
	);
};
