import { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { HiMenu } from "react-icons/hi";
import "./css/Navbar.css";

const Navbar: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  return (
    <>
      <div className={toggle ? "open-nav" : "close-nav"}>
        <div className="">
          <div
            className="flex items-right text-3xl grow text-right justify-end p-2.5 pt-6"
            style={{
              color: "white",
            }}
            onClick={() => setToggle(!toggle)}
          >
            <HiMenu />
          </div>
          <div className="flex sticky flex-col items-center flex-end text-2xl">
            <a href="https://thedogecapital.com/">
              <Icon icon="logo" width={120} height={38} />
            </a>
            <a href="https://thedogecapital.com/#about">ABOUT</a>
            <a href="https://thedogecapital.com/#roadmap" className="mx-5">
              ROADMAP
            </a>
            <a href="https://thedogecapital.com/woofbank">WOOFBANK</a>
            <a href="https://thedogecapital.com/#team" className="mx-5">
              TEAM
            </a>
            <a href="https://thedogecapital.com/rarity">RARITY</a>
          </div>
        </div>
      </div>

      {width > 600 ? (
        <Nav className="z-20 bg-black sm:bg-transparent sticky top-0 flex sm:flex-row flex-col items-center justify-between text-white sm:pl-2 sm:pr-6 pt-1 pb-6">
          <a href="https://thedogecapital.com/">
            <Icon icon="logo" width={120} height={50} />
          </a>
          <div className="flex flex-wrap sm:justify-start justify-center md:mt-0 mt-4">
            <a href="https://thedogecapital.com/#about">ABOUT</a>
            <a href="https://thedogecapital.com/#roadmap" className="mx-5">
              ROADMAP
            </a>
            <a href="https://thedogecapital.com/woofbank">WOOFBANK</a>
            <a href="https://thedogecapital.com/#team" className="mx-5">
              TEAM
            </a>
            <a href="https://thedogecapital.com/rarity">RARITY</a>
          </div>
        </Nav>
      ) : (
        <Nav className="flex items-center justify-between p-2.5">
          <a href="https://thedogecapital.com/">
            <Icon icon="logo" width={120} height={50} />
          </a>
          <div
            className="text-3xl"
            style={{
              color: "white",
            }}
            onClick={() => setToggle(!toggle)}
          >
            <HiMenu />{" "}
          </div>
        </Nav>
      )}
    </>
  );
};

const Nav = styled.nav`
  font-family: var(--font-family-2);

  & a {
    color: #fff !important;
  }

  @media screen and (max-width: 639px) {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

`;

export default Navbar;
